<template>
  <div id="page-login" class="c-app flex-row align-items-center">
    <CContainer>
      <CRow class="justify-content-center">
        <CCol md="6" xl="4">
          <CCardGroup>
            <CCard>
              <CCardBody class="login-box position-relative">

                <div class="login-logo">
                  <img src="@/assets/teehuay2.png">
                  <!-- <div class="member">
                    <img src="@/assets/agent2.png">
                  </div> -->
                  <!-- <div class="text-center py-4">
                    <h2 class="text-light">Agent Login</h2>
                  </div> -->
                </div>

                <CForm method="post" @submit.prevent="login">

                  <CInput
                    placeholder="Username"
                    autocomplete="username"
                    v-model="input.username"
                    required
                  ></CInput>

                  <CInput
                    placeholder="Password"
                    type="password"
                    autocomplete="curent-password"
                    v-model="input.password"
                    required
                  ></CInput>

                  <div class="text-center">
                    <button type="submit" class="btn-login">AGENT LOGIN</button>
                  </div>
                </CForm>

                <loading
                  :active="isLoading"
                  :can-cancel="false"
                  :is-full-page="false"
                  background-color="#EBEDEF"
                  :height="30"
                  :width="30"
                  color="#007bff"
                />
              </CCardBody>
            </CCard>
          </CCardGroup>
        </CCol>
      </CRow>
    </CContainer>
  </div>
</template>

<script>
import AuthService from '@/services/authService'
import Auth from '@/helpers/auth'
import Swal from 'sweetalert2'
import cAlert from '@/helpers/alert'

export default {
  name: 'Login',
  data() {
    return {
      isSubmit: false,
      input: {
        username: '',
        password: ''
      },
      isLoading: false
    }
  },
  methods: {
    login(e) {

      this.isLoading = true
      AuthService.login(this.input)
      .then(async (response)=>{
        console.log('response', response)
        if(response.success) {
          await Auth.saveLogin(response.data)
          this.$router.push({name: 'Dashboard'})
        }else{
          throw new Error(response?.data?.message)
        }
      })
      .catch((e)=>{
        console.log(e)
        cAlert({
          ...e,
          title: 'ไม่สำเร็จ',
          text: e.message || 'Username/Password ไม่ถูกต้อง',
          icon: 'error',
          confirmButtonText: 'OK'
        })
      })
      .finally(()=>{
        this.isLoading = false
      })
    }
  },
  mounted() {
    const viewport = document.querySelector("meta[name=viewport]")
    viewport.setAttribute('content', 'width=device-width,initial-scale=1.0')
  }
}
</script>
<style lang="scss">
#page-login {
  background-color: #000;
  background-image: url('../../assets/blue_pastel_bg.jpg');
  background-size: 100% 100%;
  background-position: center;

  .card {
    background-color: transparent;
    border: 0;

    .login-box {
      background-color: #0C2359;
      border-radius: 15px;
      padding-top: 0;
      padding-bottom: 0;
      box-shadow: 0 0 20px #FFF;

      .login-logo {
        margin-bottom: 15px;
        margin-top: -10px;
        img {
          width: 100%;
        }

        .member {
          text-align: right;
          margin-top: -60px;
          img {
            width: 50%;
          }
        }
      }

      form {
        padding: 0 30px;
        margin-top: -10px;

        .form-group {
          .form-control {
            border-radius: 5px;
            background-color: #E0F7FA;
            border: 1px solid #FFD700;
            color: #002366;
            padding: 0 5px;
          }

          .form-control::placeholder {
            color: #002366;
            opacity: 0.6;
          }

          .form-control:focus {
            outline: none !important;
            border: 0 !important;
            box-shadow: 0 0 10px #FFD700;
          }
        }

        .btn-login {
          background: linear-gradient(90deg, #DAA520, #FFD700); /* สีทองไล่ระดับ */
          border: none;
          border-radius: 8px; /* ปุ่มมีมุมโค้งมน */
          color: #002366; /* สีข้อความกรมท่า */
          font-size: 16px; /* ขนาดข้อความ */
          font-weight: bold; /* ตัวหนา */
          // padding: 10px 40px; /* ขนาดภายในของปุ่ม */
          cursor: pointer; /* เปลี่ยนเป็นรูปมือเมื่อชี้ */
          transition: all 0.3s ease-in-out; /* เพิ่มการเปลี่ยนแปลงเมื่อโฮเวอร์ */
          box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* เงา */
          margin-bottom: 20px; /* ระยะห่างของปุ่ม */;
          width: 100%;
          padding: 10px 0;
        }

        .btn-login:hover {
          background: linear-gradient(90deg, #FFD700, #DAA520); /* สลับสีไล่ระดับเมื่อโฮเวอร์ */
          box-shadow: 0 6px 10px rgba(0, 0, 0, 0.2); /* เพิ่มเงา */
          transform: translateY(-2px); /* ขยับขึ้นเล็กน้อย */
        }

        .btn-login:active {
          background: linear-gradient(90deg, #B8860B, #DAA520); /* สีเข้มขึ้นเมื่อคลิก */
          box-shadow: 0 3px 5px rgba(0, 0, 0, 0.2); /* เงาลดลง */
          transform: translateY(1px); /* ขยับลงเล็กน้อย */
        }
      }
    }
  }
}
</style>
